<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{}
  }
}
</script>

<style>
  *{
    padding: 0;
    margin: 0;
  }
</style>
