import Vue from 'vue'
import Vuex from 'vuex'

//引入并使用vuex
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        spin: {
            open: false,
            text: ''
        },
        currentMenu: '/',
        walletAddress: '',
        walletAddress2: '',
        coinBalance: '0'
    },
    mutations: {
        changeSpin (state, data) {
            state.spin.open = Boolean(data);
            state.spin.text = typeof(data) === 'string' ? data : '';
        },
        changeCurrentMenu (state, data) {
            state.currentMenu = data;
        },
        changeWalletAddress (state, data) {
            const dataLength = data.length;
            const partOfTheData = dataLength ? data.substr(0, 6) + '****' + data.substr(dataLength - 6, dataLength) : '';
            // $.cookie('walletAddress', data);
            // $.cookie('walletAddress2', partOfTheData, { expires: 30 });
            state.walletAddress = data;
            state.walletAddress2 = partOfTheData;
        },
        changeCoinBalance (state, data) {
            state.coinBalance = Number(data).toFixed(4);
        }
    },
    actions: {}
});
