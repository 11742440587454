import 'jquery.cookie'
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import router from './router'
import App from './App.vue'

//引入element-ui组件库并使用
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$ = this

Vue.prototype.$showSpin = function (text) {
  store.commit('changeSpin', text || true);
};
Vue.prototype.$hideSpin = function () {
  store.commit('changeSpin', false);
};

new Vue({
  store,
  router,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  }
}).$mount('#app')


/**
 * 全局事件总线：
 * 1.在new Vue()实例对象中添加beforeCreate(){},并在其中定义全局事件总线Vue.prototype.$bus = this
 * 2.全局事件总线通过this.$bus.$emit('事件名', 要传的数据)传递值给任意组件
 * 3.接收全局事件总线的值，需要在组件的生命周期函数mounted中完成，this.$bus.$on('事件名',(data) => {})
 * 4.记得在组件生命周期函数beforeDestroy中，用this.$bus.$off('事件名')对全局事件总线进行销毁
 */