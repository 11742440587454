import Vue from 'vue'
import VueRouter from 'vue-router'
import { MessageBox } from 'element-ui'

//引入并使用vue-router
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

//实现vue路由懒加载(按需加载)
const router = new VueRouter({
    mode:'hash',
    routes:[
        {
            path:'/',
            name:'home',
            redirect:'/main',
            component:() => import('@/views/homePage.vue'),
            children:[
                {
                    path:'main',
                    name:'home-main',
                    component:() => import('@/views/main.vue'),
                    meta:{
                        isRequired:false
                    }
                },
                {
                    path:'bord',
                    name:'home-bord',
                    component:() => import('@/views/bord.vue'),
                    meta:{
                        isRequired:true
                    }
                },
                {
                    path:'detail',
                    name:'home-detail',
                    component:() => import('@/views/detail.vue'),
                    meta:{
                        isRequired:false
                    }
                }
            ]
        },
        {
            path:'/login',
            name:'login',
            component:() => import('@/views/login.vue'),
            meta:{
                isRequired:false
            }
        }
    ]
})

//路由守卫
router.beforeEach((to,from,next) => {
    let token = sessionStorage.getItem('token')
    console.log(token)
    console.log(to)
    if(!token && to.meta.isRequired){
        MessageBox.alert('未登录，请先连接钱包进行登录', '提示', {
            type:'warning'
        })
        .then(() => {
            
          }).catch(() => {
                    
          });
    }else{
        next()
    }
    
})

export default router
